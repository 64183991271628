import * as React from "react";
import Styled from "styled-components/macro";
import Image from "gatsby-image";
import Media from "@uizard-io/styled-media-query";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";

const Container = Styled.div`
  width: 100%;
  text-align: center;
  margin-top: 16rem;
  
  ${Media.lessThan("980px")`
    margin-top: 11rem;
  `}
  
  ${Media.lessThan("small")`
    margin-top: 9rem;
  `}
`;

const ExtendedDescription = Styled(Description)`
  max-width: 88rem;
  margin: 0 auto 3.5rem auto;
`;

const ImageCollabNamesLeftWrapper = Styled.div`
    position: absolute;
    left: -25.5rem;
    top: 65%;
    transform: translateY(-50%);
   
`;

const ImageCollabNamesRightWrapper = Styled.div`
    position: absolute;
    right: -24.6rem;
    top: 72%;
    transform: translateY(-50%);
`;

const ImageCollabPhotoWrapper = Styled.div`
  max-width: 52rem;
  margin: 0 auto 10rem auto;
  position: relative;
  
   ${Media.lessThan("1100px")`
    margin: 0 auto 3rem auto;
  `}
`;

const ImageCollabPhoto = Styled(Image)`

`;

const ImageCollabNamesMobileWrapper = Styled.div`
  margin-bottom: 6.5rem;
  
  svg {
    max-width: 100%;
  }
`;

export default ({ imageA }) => (
  <Container>
    <Headline>We are remote first.</Headline>
    <ExtendedDescription>
      We aspire to be an equitable workplace that redesigns the future of work
      and empowers all uizards to learn, grow, and make an impact. One way to do
      that is by being remote-first since day one.
    </ExtendedDescription>

    <ImageCollabPhotoWrapper>
      {/*
      <MediaQuery greaterThan="1100px">
        <ImageCollabNamesLeftWrapper>
          <ImageCollabNamesLeft />
        </ImageCollabNamesLeftWrapper>
      </MediaQuery>
      */}

      <ImageCollabPhoto
        fluid={imageA.fluid}
        alt="Photo of the Uizard team in working remotely from Zoom, the video conferencing software"
      />

      {/*
      <MediaQuery greaterThan="1100px">
        <ImageCollabNamesRightWrapper>
          <ImageCollabNamesRight />
        </ImageCollabNamesRightWrapper>
      </MediaQuery>
      */}
    </ImageCollabPhotoWrapper>

    {/*
    <MediaQuery lessThan="1100px">
      <ImageCollabNamesMobileWrapper>
        <ImageCollabNamesMobile />
      </ImageCollabNamesMobileWrapper>
    </MediaQuery>
    */}
  </Container>
);
