import * as React from "react";
import { graphql } from "gatsby";
import Meta from "../../components/meta";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";

import Layout from "../../components/layout/Layout";
import Content from "../../components/layout/Content/Content";
import Shoutout from "../../components/pages/signup/Shoutout";
import AboutPresentation from "../../components/pages/about/AboutPresentation";
import CareerCTA from "../../components/pages/careers/CareerCTA";
import CareerValues from "../../components/pages/careers/CareerValues";
import CareerFunFacts from "../../components/pages/careers/CareerFunFacts";

const Container = Styled.div`
  margin: 5rem 0 5rem 0;

  ${Media.greaterThan("medium")`
    margin: 10rem 0 10rem 0;
  `}

  ${Media.lessThan("small")`
    margin: 10rem 0 5rem 0;
  `}
`;

const Column = Styled.div`
  ${Media.greaterThan("700px")`
    min-width: 0;
  `}
`;

const Careers = ({ data }) => (
  <Layout>
    <Meta
      title="Uizard Careers | Explore Roles With Uizard | Uizard"
      description="We are conjuring a culture where you can learn, grow, and impact the future of design. Join the no-design movement with Uizard today. Apply now."
      url="/careers/"
    />

    <Content>
      <Container>
        <Content narrow>
          <CareerCTA
            headline="Lets make magic happen."
            description="We are conjuring a culture where you can learn, grow, and impact the future of design and work. Want to inspire the no-design movement and empower non-designers around the world?"
            link="https://www.linkedin.com/company/uizard/jobs/"
            ctaText="See open roles"
          />

          <AboutPresentation imageA={data.presentationImageA.childImageSharp} />

          <CareerValues
            imageA={data.missionImageA.childImageSharp}
            imageB={data.missionImageB.childImageSharp}
          />

          <CareerFunFacts headline="Helping you thrive" />

          <CareerCTA
            headline="Our commitment to you."
            description="Starting a new job is an adventure, especially the job search leading up to it. We want you to feel inspired and wand-erful throughout your application experience with us.
            <br/>
            We have created a few resources make a transparent, equitable, and magical expriences."
            link="https://uizard.notion.site/uizard/Our-Uizarding-World-c14673abb5344eb2bf3edbf386d3a7da"
            ctaText="Our Uizarding World"
          />
        </Content>
      </Container>
    </Content>
    <Content>
      <Shoutout
        headline="Join the revolution"
        description="Design your next project today"
        cta="Sign up for free"
        imageA={data.shoutoutImageA.childImageSharp}
      />
    </Content>
  </Layout>
);

export default Careers;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    presentationImageA: file(
      relativePath: { eq: "careers/presentation-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1040, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    missionImageA: file(relativePath: { eq: "careers/mission-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1044, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    missionImageB: file(relativePath: { eq: "careers/mission-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
