import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import MediaQuery from "../../../layout/MediaQuery";

const Container = Styled.div`
  width: 100%
  text-align: center;
  margin-top: 20rem;
  
   ${Media.lessThan("medium")`
    margin-top: 10rem;
  `}
`;

const ImageMobile = Styled(Image)`
  width: 100%;
  margin: 4.5rem 0 4.5rem 0;
`;

const ImageDesktop = Styled(Image)`
  width: 100%;
`;

const Section = Styled.div`
`;

const H5Extended = Styled.h5`
    font-size: 2rem;
    font-weight: 500;
    margin: 0 0 2rem 0;
    
    ${Media.greaterThan("large")`
        font-size: 2.8rem;
  `}
`;

const ExtendedDescription = Styled(Description)`
  margin: 0 auto 3.2rem auto;
  padding-bottom: ${(props) => (props.separator ? "3.2rem" : "0")};
  border-bottom: ${(props) => (props.separator ? "solid #C1C1C1 1px" : "none")};
`;

const ExtendedHeadline = Styled(Headline)`
  text-align: center;
  margin-bottom: 8rem;
  margin-top: 8rem;

  ${Media.greaterThan("900px")`
        margin-top: 0;
    `}
`;

const Columns = Styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    
    ${Media.greaterThan("900px")`
        flex-direction: row;
    `}
`;

const ColumnText = Styled.div`
    width: 100%;
    
     ${Media.greaterThan("900px")`
        width: 50%;
    `}
`;

const ColumnIllustration = Styled(MediaQuery)`
    width: 100%;
    
     ${Media.greaterThan("900px")`
        width: 60%;
        max-width: 634px;
        margin-right: 90px;
    `}
`;

type CareerValuesProps = {
  imageA: GatsbyImageProps;
  imageB: GatsbyImageProps;
};

export default ({ imageA, imageB }: CareerValuesProps) => (
  <Container>
    <ExtendedHeadline>Our values guide us.</ExtendedHeadline>

    <Columns>
      <ColumnIllustration greaterThan="670px">
        <ImageDesktop
          fluid={"fluid" in imageA ? imageA.fluid : null}
          alt="Picture of the Uizard team enjoying social activities"
        />
      </ColumnIllustration>

      <ColumnText>
        <Section>
          <H5Extended>Go fast and be bold</H5Extended>
          <ExtendedDescription separator>
            We strive for experiences. It means making the impossible possible,
            creating the future of no-design, and innovating with courage. We
            empower all uizards to be proactive owners who experiment and aren't
            afraid to break things.
          </ExtendedDescription>
        </Section>

        <Section>
          <H5Extended>Stay open-minded</H5Extended>
          <ExtendedDescription separator>
            We're solution-minded, coming from a place of curiosity, embracing
            change, as well as failure. For us, it's staying hungry for
            knowledge while grounding ourselves with humility, empathy, and
            speaking up respectfully.
          </ExtendedDescription>
        </Section>

        <Section>
          <H5Extended>Have fun while working</H5Extended>
          <ExtendedDescription>
            We empower each other to do work that energizes us—always exploring
            new ideas and opportunities. It also means being professional
            without a suit, and partaking in magical puns. Isn't that
            wand-erful?
          </ExtendedDescription>
        </Section>
      </ColumnText>

      <ColumnIllustration lessThan="670px">
        <ImageMobile
          fluid={"fluid" in imageB ? imageB.fluid : null}
          alt="Picture of the Uizard team enjoying social activities"
        />
      </ColumnIllustration>
    </Columns>
  </Container>
);
