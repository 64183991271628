import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import Tagline from "../../../texts/Tagline";
import Button from "../../../buttons/Button";
import { HybridLink } from "../../../layout/Footer";

const Container = Styled.div`
  width: 100%
  text-align: center;
  margin-bottom: 10rem;
  overflow: hidden;
  border-radius: 10px;

  ${Media.lessThan("medium")`
    margin-bottom: 5rem;
  `}
`;

const ExtendedButton = Styled(Button)`
  font-size: 1.6rem;
  border-radius: 1rem;
  ${Media.lessThan("small")`
    width: 100%;
  `}
`;

const TextContainer = Styled.div`
  text-align: center;
  margin-bottom: 3.5rem;
  margin-top: 10rem;
`;

const ExtendedDescription = Styled(Description)`
  max-width: 65rem;
  margin: 0 auto 3.5rem auto;
`;

const TaglineExtended = Styled(Tagline)`
  color: #7f7f7f;
`;

const ExtendedLink = Styled(HybridLink)``;

type CareerCTAProps = {
  headline: string;
  description: string;
  link: string;
  ctaText: string;
};

export default ({ headline, description, link, ctaText }: CareerCTAProps) => (
  <Container>
    <TextContainer>
      <TaglineExtended as="h1">Careers</TaglineExtended>
      <Headline as="h2">{headline}</Headline>

      {description.split("<br/>").map((line) => {
        return <ExtendedDescription>{line}</ExtendedDescription>;
      })}

      <ExtendedLink to={link}>
        <ExtendedButton type="submit" variant="primarylight" solid>
          {ctaText}
        </ExtendedButton>
      </ExtendedLink>
    </TextContainer>
  </Container>
);
