import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";

const Container = Styled.div`
  text-align: center;
  margin-top: 15rem;
  
   ${Media.lessThan("medium")`
    margin-top: 10rem;
  `}
`;

const Columns = Styled.div`
  display: flex; 
  justify-content: space-between;
  
   ${Media.lessThan("700px")`
    flex-direction: column;
  `}
`;

const Column = Styled.div`
  width: calc(20%);
  ${Media.lessThan("700px")`
    width: 100%;
  `}
`;

const PerkItem = Styled(Description)`
  font-size: 1.8rem;
  margin: 5rem 0;
  font-weight: 500;

  ${Media.lessThan("700px")`
    margin: 3rem 0 0 0;
  `}
`;

export default ({ headline }) => (
  <Container>
    <Headline>{headline}</Headline>
    <Columns>
      <Column>
        <PerkItem>Remote-first freedom and balance</PerkItem>
        <PerkItem>Generous time off to re-energize</PerkItem>
      </Column>

      <Column>
        <PerkItem>Competitive salary and meaningful equity</PerkItem>
        <PerkItem>Find your ideal work space</PerkItem>
      </Column>

      <Column>
        <PerkItem>Equipment for your work</PerkItem>
        <PerkItem>Parental leave for you and your family</PerkItem>
      </Column>

      <Column>
        <PerkItem>In person year trip and onsites in Copenhagen</PerkItem>
        <PerkItem>Unlimited wellbeing days</PerkItem>
      </Column>
    </Columns>
  </Container>
);
